import React, { useState, useEffect } from 'react'
import Pagina from '../components/Pagina'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'

import HelpIcon from '../images/jogo-linha-do-tempo-lwart/icon/help.svg'
import AnalyticsIcon from '../images/jogo-linha-do-tempo-lwart/icon/analytics.svg'
import iconLwart from '../images/jogo-linha-do-tempo-lwart/icon/icon-lwart.png'
import logoLwart from '../images/jogo-linha-do-tempo-lwart/Lwart_50_Anos_Positivo_Preferencial.svg'

import './styles/jogo-linha-do-tempo-lwart.scss'

const JogoLinhaDoTempo = ({ data }) => {
  const initialCards = [
    { id: 1, year: 1951, image: getImage(data.image1), text: 'Os irmãos Luiz, Alberto, Wilson e Renato, com o apoio do pai, iniciaram a Trecenti Indústria e Comércio, uma serralheria para confecção de vitrôs, janelas, portas e parafusos, entre outros materiais.', title: 'Nasce a Trecenti Indústria' },
    { id: 2, year: 1975, image: getImage(data.image2), text: 'Fundação da Lwart Lubrificantes em 30 de janeiro. A empresa tinha capacidade para processar 80 mil litros/mês e contava com apenas um coletor.', title: 'Fundação da Lwart Lubrificantes' },
    { id: 3, year: 1978, image: getImage(data.image3), text: 'A Lwart Lubrificantes estava construindo um novo local e iria sair do centro de Lençóis Paulista para instalar uma nova unidade na Rodovia Marechal Rondon, onde está até hoje.', title: 'Nova Unidade Estratégica' },
    { id: 4, year: 1979, image: getImage(data.image4), text: 'Venda da Trecenti Indústria e Comércio. Neste ano, a família decidiu se dedicar exclusivamente à coleta e ao rerrefino de óleo lubrificante usado.', title: 'Foco no Rerrefino de Óleo' },
    { id: 5, year: 1998, image: getImage(data.image5), text: 'Ano da criação do primeiro Programa de Trainees do Grupo Lwart.', title: '1º Programa de Trainees Lwart' },
    { id: 6, year: 2002, image: getImage(data.image6), text: 'Realizado o primeiro Natal Cidadão, ação que contempla a entrega de presentes de Natal para jovens atendidos por creches e organizações sociais.', title: '1º Natal Cidadão da Lwart' },
    { id: 7, year: 2008, image: getImage(data.image7), text: 'O volume de óleo usado processado ultrapassou 100 milhões de litros anuais, dez vezes o volume da capacidade inicial da planta.', title: 'Volume Processado Recorde!' },
    { id: 8, year: 2020, image: getImage(data.image8), text: 'A Lwart Lubrificantes passou por uma transformação de marca e mudou o nome para Lwart Soluções Ambientais, refletindo o compromisso com a sustentabilidade.', title: 'Lwart Soluções Ambientais' },
    { id: 9, year: 2020, image: getImage(data.image9), text: 'Lançamento do 1º Relatório de Sustentabilidade, um documento que indica os principais indicadores sociais, ambientais e econômicos de uma empresa.', title: '1º Relatório de Sustentabilidade' },
    { id: 10, year: 2022, image: getImage(data.image10), text: 'A Lwart iniciou uma parceria relevante com grandes competições do automobilismo.', title: 'Parceria no Automobilismo' },
    { id: 11, year: 2023, image: getImage(data.image11), text: 'Neste ano, a empresa produziu 176 milhões de litros de óleos básicos GII, recorde histórico e um case de economia circular no país.', title: 'Novo Recorde de Produção Histórico' },
    { id: 12, year: 2023, image: getImage(data.image12), text: 'A Lwart anunciou investimento de R$ 1 bilhão na expansão de uma nova fábrica, aumentando a capacidade de processamento em 60%, passando dos atuais 240 milhões de litros/ano para 380 milhões.', title: 'Investimento de R$ 1 Bilhão na Expansão' },
    { id: 13, year: 2024, image: getImage(data.image13), text: 'A Lwart inaugurou o primeiro centro de coleta de Porto Velho - agora, com 19 centros, atende mais de 3.500 municípios em todo o país, reafirmando o compromisso com a sustentabilidade e a responsabilidade ambiental.', title: 'Expansão da Rede de Coleta Nacional' },
    { id: 14, year: 2025, image: getImage(data.image14), text: 'Um marco na empresa: a Lwart possui 108 colaboradores com mais de 20 anos de casa, graças a um ambiente de trabalho seguro, diverso, ético, respeitoso e com oportunidades de desenvolvimento profissional.', title: 'Longevidade e Valorização Humana' }
  ]
  const [availableCards, setAvailableCards] = useState([])
  const [placedCards, setPlacedCards] = useState([])
  const [hoverIndex, setHoverIndex] = useState(null)
  const [gameStarted, setGameStarted] = useState(false)
  const [gameCards, setGameCards] = useState([])
  const [lives, setLives] = useState(3)
  const [gameOver, setGameOver] = useState(false)
  const [helpModalOpen, setHelpModalOpen] = useState(false)
  const [elapsedTime, setElapsedTime] = useState(0)
  const [analyticsModalOpen, setAnalyticsModalOpen] = useState(false)
  const [gameWon, setGameWon] = useState(false)
  const [metricas, setMetricas] = useState({
    jogosIniciados: 0,
    tempoTotalJogado: 0,
    cartasCorretas: 0,
    cartasIncorretas: 0,
    jogosPerdidos: 0,
    jogosVencidos: 0
  })
  const [draggingCardId, setDraggingCardId] = useState(null)
  const [cardOffsetX, setCardOffsetX] = useState(0)
  const [cardOffsetY, setCardOffsetY] = useState(0)
  const [isMobileDragging, setIsMobileDragging] = useState(false)

  const formatTime = (timeInSeconds) => {
    const minutes = String(Math.floor(timeInSeconds / 60)).padStart(2, '0')
    const seconds = String(timeInSeconds % 60).padStart(2, '0')
    return `${minutes}:${seconds}`
  }

  const carregarMetricas = () => {
    const metricasSalvas = localStorage.getItem('jogoLwartMetricas')
    if (metricasSalvas) {
      setMetricas(JSON.parse(metricasSalvas))
    }
  }

  const salvarMetricas = () => {
    localStorage.setItem('jogoLwartMetricas', JSON.stringify(metricas))
  }

  useEffect(() => {
    carregarMetricas()
  }, [])

  useEffect(() => {
    salvarMetricas()
  }, [metricas])

  const shuffleAndSelectCards = () => {
    const fisherYatesShuffle = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1))
        const temp = array[i]
        array[i] = array[j]
        array[j] = temp
      }
      return array
    }
    let shuffled = [...initialCards]
    for (let i = 0; i < 5; i++) {
      shuffled = fisherYatesShuffle(shuffled)
    }
    const selected = shuffled.slice(0, 9)
    const ordered = [...selected].sort((a, b) =>
      a.year - b.year
    )
    return { ordered, shuffled: selected }
  }
  const startGame = () => {
    setMetricas(prevState => ({
      ...prevState,
      jogosIniciados: prevState.jogosIniciados + 1
    }))
    const { ordered, shuffled } = shuffleAndSelectCards()
    setGameCards(ordered)
    const firstPlacedCard = ordered[0]
    setPlacedCards([firstPlacedCard])
    const remainingCards = shuffled.filter((card) => card.id !== firstPlacedCard.id)

    setAvailableCards([remainingCards[0]])
    setGameStarted(true)
    setLives(3)
    setGameOver(false)
    setGameWon(false)
    setElapsedTime(0)
  }
  const [isMobile, setIsMobile] = useState(false)
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsMobile('ontouchstart' in window)
    }
  }, [])
  const handleDragStart = (event, cardId) => {
    if (isMobile) return
    event.dataTransfer.setData('cardId', cardId)
  }
  const handleDragOverCard = (event, targetCardIndex) => {
    event.preventDefault()
    setHoverIndex(targetCardIndex)
  }
  const handleDropOnTimeline = (event) => {
    const cardId = parseInt(event.dataTransfer.getData('cardId'))
    const draggedCard = availableCards.find((card) => card.id === cardId)
    if (draggedCard) {
      const newPlacedCards = [...placedCards]
      let isCorrect = true
      let currentLives = lives
      const insertIndex = hoverIndex !== null && hoverIndex !== undefined ? hoverIndex : newPlacedCards.length
      const leftCard = insertIndex > 0 ? newPlacedCards[insertIndex - 1] : null
      const rightCard = insertIndex < newPlacedCards.length ? newPlacedCards[insertIndex] : null
      const isLeftValid = !leftCard || (draggedCard.year >= leftCard.year)
      const isRightValid = !rightCard || (draggedCard.year <= rightCard.year)
      isCorrect = isLeftValid && isRightValid
      if (isCorrect) {
        setMetricas(prevState => ({
          ...prevState,
          cartasCorretas: prevState.cartasCorretas + 1
        }))
        newPlacedCards.splice(
          hoverIndex !== null && hoverIndex !== undefined ? hoverIndex : newPlacedCards.length,
          0,
          { ...draggedCard, isIncorrect: false }
        )
      } else {
        setMetricas(prevState => ({
          ...prevState,
          cartasIncorretas: prevState.cartasIncorretas + 1
        }))
        if (lives > 0) {
          setLives(lives - 1)
          currentLives = lives - 1
        }
        const correctIndex = newPlacedCards.findIndex(
          (card) =>
            draggedCard.year < card.year
        )
        if (correctIndex !== -1) {
          newPlacedCards.splice(correctIndex, 0, { ...draggedCard, isIncorrect: true })
        } else {
          newPlacedCards.push({ ...draggedCard, isIncorrect: true })
        }
      }
      if (currentLives <= 0 && !isCorrect) {
        setGameOver(true)
        setMetricas(prevState => ({
          ...prevState,
          jogosPerdidos: prevState.jogosPerdidos + 1
        }))
      } else if (availableCards.length === 1 && isCorrect) {
      }
      setPlacedCards(newPlacedCards)
      setAvailableCards(availableCards.filter((card) => card.id !== draggedCard.id))
      if (!gameWon) {
        const nextCard = gameCards.find(
          (card) => !newPlacedCards.some((placedCard) => placedCard.id === card.id)
        )
        if (nextCard) {
          setAvailableCards([nextCard])
        } else if (availableCards.length <= 1 && !nextCard) {
          setAvailableCards([])
          if (lives > 0) {
            setGameWon(true)
            setGameOver(true)
            setMetricas(prevState => ({
              ...prevState,
              jogosVencidos: prevState.jogosVencidos + 1
            }))
          }
        }
      }
    }
    setHoverIndex(null)
  }
  const handleTouchStart = (event, cardId, touch) => {
    if (!isMobile) return
    event.preventDefault()
    setDraggingCardId(cardId)
    const cardElement = event.target.closest('.jogo-linha-do-tempo-card')
    if (cardElement) {
      const rect = cardElement.getBoundingClientRect()
      setCardOffsetX(touch.clientX - rect.left)
      setCardOffsetY(touch.clientY - rect.top)
    }
    setIsMobileDragging(true)
    event.target.closest('.jogo-linha-do-tempo-card').classList.add('jogo-linha-do-tempo-card--dragging')
  }
  const handleTouchMove = (event) => {
    if (!isMobileDragging || draggingCardId === null) return
    event.preventDefault()
    const touch = event.touches[0]
    const currentX = touch.clientX
    const currentY = touch.clientY
    const draggedCardElement = document.querySelector('.jogo-linha-do-tempo-card.jogo-linha-do-tempo-card--dragging')
    if (draggedCardElement) {
      draggedCardElement.style.position = 'fixed'
      draggedCardElement.style.zIndex = '1000'
      draggedCardElement.style.left = `${currentX - cardOffsetX}px`
      draggedCardElement.style.top = `${currentY - cardOffsetY}px`
      draggedCardElement.style.pointerEvents = 'none'
      const timelineElement = document.querySelector('.jogo-linha-do-tempo-timeline')
      if (timelineElement) {
        const timelineRect = timelineElement.getBoundingClientRect()
        if (currentX >= timelineRect.left && currentX <= timelineRect.right && currentY >= timelineRect.top && currentY <= timelineRect.bottom) {
          let bestHoverIndex = placedCards.length
          const timelineCardElements = document.querySelectorAll('.jogo-linha-do-tempo-timeline-card')
          timelineCardElements.forEach((cardTimelineElement, index) => {
            if (cardTimelineElement) {
              const cardRect = cardTimelineElement.getBoundingClientRect()
              if (currentX < cardRect.left + cardRect.width / 2) {
                bestHoverIndex = Math.min(bestHoverIndex, index)
              }
            }
          })
          setHoverIndex(bestHoverIndex)
        } else {
          setHoverIndex(null)
        }
      }
    }
  }
  const handleTouchEnd = (event) => {
    if (!isMobileDragging || draggingCardId === null) return
    setIsMobileDragging(false)
    const draggedCard = availableCards.find((card) => card.id === draggingCardId)
    const draggedCardElement = document.querySelector('.jogo-linha-do-tempo-card.jogo-linha-do-tempo-card--dragging')
    if (draggedCardElement) {
      draggedCardElement.style.zIndex = ''
      draggedCardElement.style.left = ''
      draggedCardElement.style.top = ''
      draggedCardElement.style.pointerEvents = ''
      draggedCardElement.classList.remove('jogo-linha-do-tempo-card--dragging')
    }
    if (draggedCard) {
      const simulatedDropEvent = {
        dataTransfer: {
          getData: () => String(draggingCardId)
        }
      }
      handleDropOnTimeline(simulatedDropEvent)
    }
    setDraggingCardId(null)
    setHoverIndex(null)
  }
  const renderAvailableCards = () => {
    return availableCards.map(card => (
      <div
        key={card.id}
        className="jogo-linha-do-tempo-card"
        onDragOver={(e) => e.preventDefault()}
        draggable={!isMobile}
        onDragStart={(event) => handleDragStart(event, card.id)}
        onTouchStart={(event) => handleTouchStart(event, card.id, event.touches[0])}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <p className="text-card">{card.text}</p>
        <GatsbyImage className="jogo-linha-do-tempo-image-card" image={card.image} alt={card.title} />
      </div>
    ))
  }
  const renderLives = () => {
    const livesArray = []
    for (let i = 0; i < 3; i++) {
      if (i < lives) {
        livesArray.push(
          <span key={i} className="jogo-linha-do-tempo-heart-container">
            <img
              src={iconLwart}
              className="jogo-linha-do-tempo-heart-icon"
              alt="Ícone Lwart"
            />
          </span>
        )
      }
    }
    return livesArray
  }
  useEffect(() => {
    let timer
    if (gameStarted && !gameOver && !gameWon) {
      timer = setInterval(() => {
        setElapsedTime((prev) => prev + 1)
        setMetricas(prevState => ({
          ...prevState,
          tempoTotalJogado: prevState.tempoTotalJogado + 1
        }))
      }, 1000)
    }
    return () => clearInterval(timer)
  }, [gameStarted, gameOver, gameWon])
  const abrirModalAnalytics = () => {
    setAnalyticsModalOpen(true)
  }
  const fecharModalAnalytics = () => {
    setAnalyticsModalOpen(false)
  }

  return (
    <>
      <div className='position-relative'>
        <Pagina pagina={data.pagina} />
        <div className="jogo-linha-do-tempo-background"></div>
        <div className="positive-relative w-100 h-auto d-lg-block d-none">
          <video autoPlay muted loop id="video-play">
            <source src="https://d29gz1j979b5he.cloudfront.net/novo-video-jogo (video-converter.com).mp4" type="video/mp4" />
          </video>
        </div>
        {!gameStarted && (
          <div className="jogo-linha-do-tempo-start-overlay">
            <div className="jogo-linha-do-tempo-start-card">
              <div className="jogo-linha-do-tempo-logo-container">
                <img className="jogo-linha-do-tempo-logo" src={logoLwart} alt="Logo Lwart" />
              </div>
              <h2>QUANDO ACONTECEU?</h2>
              <div className="linha-container">
                <div className="linha"></div>
                <span>Especial 50 anos</span>
                <div className="linha"></div>
              </div>
              <p>Já pensou em se informar enquanto joga?</p>
              <p>Com nosso jogo interativo, você pode conhecer a trajetória de uma empresa que faz a
                diferença no cuidado com o meio ambiente: a Lwart Soluções Ambientais. Ao longo de
                seus 50 anos, a Lwart se consolidou como a segunda maior rerrefinaria de Óleo
                Lubrificante Usado do mundo, desempenhando um papel essencial para o futuro do
                planeta.</p>
              <p><strong>O desafio é simples:</strong> organize as cartas em ordem cronológica e descubra os marcos
                mais importantes dessa história de inovação e sustentabilidade.</p>
              <button onClick={startGame} className="jogo-linha-do-tempo-button">
                Vamos Jogar?
              </button>
            </div>
          </div>
        )}
        {gameStarted && !gameOver && !gameWon && (
          <>
            <div className='jogo-linha-do-tempo-container positive-relative'>
              <div className="jogo-linha-do-tempo d-flex flex-column  align-items-center justify-content-center mb-5">
                <div className='jogo-linha-do-tempo-lives-wrapper'>
                  <p className='jogo-linha-do-tempo-timer mt-0 mb-0'>
                    {formatTime(elapsedTime)}
                  </p>
                  <span className="jogo-linha-do-tempo-lives">{renderLives()}</span>
                </div>
                <div className='jogo-linha-do-tempo-help-wrapper'>
                  <img
                    className="jogo-linha-do-tempo-help-icon"
                    src={HelpIcon}
                    onClick={() => setHelpModalOpen(true)}
                    alt="Ícone de Ajuda"
                  />
                </div>
                <div
                  className='jogo-linha-do-tempo-analytics-wrapper'
                  onClick={abrirModalAnalytics}
                  style={{ cursor: 'pointer' }}
                  title="Ver Métricas do Jogo"
                >
                  <img
                    className="jogo-linha-do-tempo-analytics-icon"
                    src={AnalyticsIcon}
                    alt="Ícone de Ajuda"
                  />
                </div>
              </div>
              <div className="jogo-linha-do-tempo-available-cards-area">
                {renderAvailableCards()}
              </div>
              <div
                className="jogo-linha-do-tempo-timeline"
                onDragOver={(e) => e.preventDefault()}
                onDrop={handleDropOnTimeline}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
              >
                {placedCards.map((card, index) => (
                  <React.Fragment key={card.id}>
                    {hoverIndex === index && <div className="jogo-linha-do-tempo-drop-placeholder"></div>}
                    <div
                      className={`jogo-linha-do-tempo-timeline-card ${card.isIncorrect ? 'incorrect' : 'correct'
                        }`}
                      draggable={false}
                      onDragOver={(event) => handleDragOverCard(event, index)}
                    >
                      {card.id !== -1 && (
                        <>
                          <p><strong>{card.title}</strong></p>
                          <GatsbyImage className="jogo-linha-do-tempo-image-card-reduzido" image={card.image} alt={card.title} />
                          <p>{card.year}</p>
                        </>
                      )}
                    </div>
                  </React.Fragment>
                ))}
                {hoverIndex === placedCards.length && <div className="jogo-linha-do-tempo-drop-placeholder"></div>}
                <div className='jogo-linha-do-tempo-dotted-line'></div>
              </div>
            </div>
          </>
        )}
        {gameOver && !gameWon && (
          <div className="jogo-linha-do-tempo-start-overlay">
            <div className="jogo-linha-do-tempo-start-card">
              <div className="jogo-linha-do-tempo-logo-container">
                <img className="jogo-linha-do-tempo-logo" src={logoLwart} alt="Logo Lwart" />
              </div>
              <h2>QUANDO ACONTECEU?</h2>
              <div className="linha-container">
                <div className="linha"></div>
                <span>Especial 50 anos</span>
                <div className="linha"></div>
              </div>
              <h2>GAME OVER</h2>
              <p>Toda história é marcada por momentos especiais, e a trajetória da Lwart Soluções
                Ambientais é um reflexo disso. Há 50 anos, dedicamos nosso trabalho a cuidar do
                futuro, transformando o hoje. E nossa missão vai além: seguimos comprometidos em
                criar soluções sustentáveis, que assegurem um futuro de infinitas possibilidades para
                as próximas gerações.</p>
              <p><strong>Game Over... por enquanto! Mas você já aprendeu muito sobre a Lwart! Que tal usar esse conhecimento e tentar de novo? </strong></p>
              <button onClick={startGame} className="jogo-linha-do-tempo-button">
                Jogar Novamente?
              </button>
            </div>
          </div>
        )}
        {gameWon && (
          <div className="jogo-linha-do-tempo-start-overlay">
            <div className="jogo-linha-do-tempo-start-card">
              <div className="jogo-linha-do-tempo-logo-container">
                <img className="jogo-linha-do-tempo-logo" src={logoLwart} alt="Logo Lwart" />
              </div>
              <h2>QUANDO ACONTECEU?</h2>
              <div className="linha-container">
                <div className="linha"></div>
                <span>Especial 50 anos</span>
                <div className="linha"></div>
              </div>
              <h2>PARABÉNS, VOCÊ VENCEU!</h2>
              <p>Você organizou a linha do tempo da Lwart Soluções Ambientais com maestria! </p>
              <p>Toda história é marcada por momentos especiais, e a trajetória da Lwart Soluções
                Ambientais é um reflexo disso. Há 50 anos, dedicamos nosso trabalho a cuidar do
                futuro, transformando o hoje. E nossa missão vai além: seguimos comprometidos em
                criar soluções sustentáveis, que assegurem um futuro de infinitas possibilidades para
                as próximas gerações.</p>
              <p><strong>Você desvendou os segredos da Lwart! Mas será que pegou todos os detalhes? Jogue novamente e descubra mais!</strong></p>
              <button onClick={startGame} className="jogo-linha-do-tempo-button">
                Jogar Novamente?
              </button>
            </div>
          </div>
        )}
        {/* Modal de Ajuda */}
        {helpModalOpen && (
          <div className="jogo-linha-do-tempo-start-overlay">
            <div className="jogo-linha-do-tempo-start-card">
              <div className="jogo-linha-do-tempo-logo-container">
                <img className="jogo-linha-do-tempo-logo" src={logoLwart} alt="Logo Lwart" />
              </div>
              <h2>QUANDO ACONTECEU?</h2>
              <div className="linha-container">
                <div className="linha"></div>
                <span>Especial 50 anos</span>
                <div className="linha"></div>
              </div>
              <h2>Precisa de Ajuda?</h2>
              <p>As regras são simples, mas não quer dizer que o jogo seja fácil.</p>
              <p>Coloque as cartas em ordem cronológica. Se errar três vezes, o jogo acaba.</p>
              <p>E aí? Quantas cartas será que você consegue organizar?</p>
              <button onClick={() => setHelpModalOpen(false)} className="jogo-linha-do-tempo-button">
                OK, ENTENDI!
              </button>
            </div>
          </div>
        )}

        {analyticsModalOpen && (
          <div className="jogo-linha-do-tempo-start-overlay">
            <div className="jogo-linha-do-tempo-start-card">
              <div className="jogo-linha-do-tempo-logo-container">
                <img className="jogo-linha-do-tempo-logo" src={logoLwart} alt="Logo Lwart" />
              </div>
              <h2>QUANDO ACONTECEU?</h2>
              <div className="linha-container">
                <div className="linha"></div>
                <span>Especial 50 anos</span>
                <div className="linha"></div>
              </div>
              <h2>Métricas do Jogo</h2>
              <div className="metricas-lista">
                <p>Tempo Total Jogado: {formatTime(metricas.tempoTotalJogado) || '00:00'}</p>
                <p>Cartas Corretas: {metricas.cartasCorretas || 0}</p>
                <p>Cartas Incorretas: {metricas.cartasIncorretas || 0}</p>
                <p>Jogos Perdidos: {metricas.jogosPerdidos || 0}</p>
                <p>Jogos Vencidos: {metricas.jogosVencidos || 0}</p>
              </div>
              <button onClick={fecharModalAnalytics} className="jogo-linha-do-tempo-button">
                Fechar
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default JogoLinhaDoTempo

export const query = graphql`
  query {
    pagina: contentfulPagina(path: { eq: "/jogo-linha-do-tempo-lwart/" }) {
      path
      metaTitle
      metaDescription
      pageTitle
      tags
      ogImage {
        localFile {
          publicURL
        }
      }
    }
    paralax: file(relativePath: { eq: "blog/header.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    paralaxMobile: file(relativePath: { eq: "blog/blog.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, width: 400, height: 350, layout: CONSTRAINED)
      }
    }
    
    image1: file(relativePath: { eq: "jogo-linha-do-tempo-lwart/image-cards/image-1.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: CONSTRAINED)
      }
    }
    image2: file(relativePath: { eq: "jogo-linha-do-tempo-lwart/image-cards/image-2.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: CONSTRAINED)
      }
    }
    image3: file(relativePath: { eq: "jogo-linha-do-tempo-lwart/image-cards/image-3.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: CONSTRAINED)
      }
    }
    image4: file(relativePath: { eq: "jogo-linha-do-tempo-lwart/image-cards/image-4.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: CONSTRAINED)
      }
    }
    image5: file(relativePath: { eq: "jogo-linha-do-tempo-lwart/image-cards/image-5.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: CONSTRAINED)
      }
    }
    image6: file(relativePath: { eq: "jogo-linha-do-tempo-lwart/image-cards/image-6.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: CONSTRAINED)
      }
    }
    image7: file(relativePath: { eq: "jogo-linha-do-tempo-lwart/image-cards/image-7.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: CONSTRAINED)
      }
    }
    image8: file(relativePath: { eq: "jogo-linha-do-tempo-lwart/image-cards/image-8.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: CONSTRAINED)
      }
    }
    image9: file(relativePath: { eq: "jogo-linha-do-tempo-lwart/image-cards/image-9.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: CONSTRAINED)
      }
    }
    image10: file(relativePath: { eq: "jogo-linha-do-tempo-lwart/image-cards/image-10.jpeg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: CONSTRAINED)
      }
    }
    image11: file(relativePath: { eq: "jogo-linha-do-tempo-lwart/image-cards/image-11.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: CONSTRAINED)
      }
    }
    image12: file(relativePath: { eq: "jogo-linha-do-tempo-lwart/image-cards/image-12.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: CONSTRAINED)
      }
    }
    image13: file(relativePath: { eq: "jogo-linha-do-tempo-lwart/image-cards/image-13.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: CONSTRAINED)
      }
    }
    image14: file(relativePath: { eq: "jogo-linha-do-tempo-lwart/image-cards/image-14.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: CONSTRAINED)
      }
    }
  }
`
